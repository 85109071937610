// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sample-about-js": () => import("./../../../src/pages/sample-about.js" /* webpackChunkName: "component---src-pages-sample-about-js" */),
  "component---src-pages-sample-contact-js": () => import("./../../../src/pages/sample-contact.js" /* webpackChunkName: "component---src-pages-sample-contact-js" */),
  "component---src-pages-sample-home-js": () => import("./../../../src/pages/sample-home.js" /* webpackChunkName: "component---src-pages-sample-home-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/BlogPage.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-blog-post-page-js": () => import("./../../../src/templates/BlogPostPage.js" /* webpackChunkName: "component---src-templates-blog-post-page-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/ProductPage.js" /* webpackChunkName: "component---src-templates-product-page-js" */)
}

